import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {axiosLoginUser, axiosRegisterUser, getUserAccount} from "../../api/authApi";

export const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        isLoggedIn: false,
        isLoginError: false,
        isRegisterIn: false,
        isRegisterError: false,
        name: '',
        role: '',
        token: '',
    },
    reducers: {
        logout: (state, action) => {
            state.isLoggedIn = false;
            state.isLoginError = false;
            state.name = '';
            state.role = '';
            state.token = ''
            localStorage.removeItem('mmo5sjwt');
        },
        toggleIsRegisterIn: (state, action ) => {
            state.isRegisterIn = false;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.name = action.payload.data.name;
                state.role = action.payload.data.role;
                state.token = action.payload.token;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.isLoginError = false;
                state.name = action.payload.user.name;
                state.role = action.payload.user.role;
                state.token = action.payload.user.token;
                localStorage.setItem("mmo5sjwt", action.payload.user.token);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoggedIn = false;
                state.isLoginError = true;
                state.name = "";
                state.role = "";
                state.token = "";
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.isRegisterIn = true;
                state.isLoginError = false;
            })
    }
});

export const getUser = createAsyncThunk("auth/getUser", async (token) => {
    const response = await getUserAccount(token.token);
    return {data: response.data, token: token.token};
});

export const loginUser = createAsyncThunk("auth/loginUser", async  (data) => {
    const response = await axiosLoginUser(data);
    return {user : response.data}
});

export const registerUser = createAsyncThunk("auth/registerUser", async  (data) => {
    const response = await axiosRegisterUser(data);
    return {user : response.data}
});
