import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {axiosGetBank, axiosPostBank} from "../../api/bankApi";

export const bankSlice = createSlice({
    name: 'bankSlice',
    initialState: {
        name: '',
        stk: '',
        nameUser: '',
        isEdit: false,
    },
    reducers: {
        toggleEdit: (state, action) => {
            state.isEdit = !state.isEdit;
        },
        clearBank: (state) => {
            state.name = '';
            state.stk = '';
            state.nameUser = '';
            state.isEdit = false;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBank.fulfilled, (state, action) => {
                state.name = action.payload.name;
                state.stk = action.payload.stk;
                state.nameUser = action.payload.nameUser;
            })
            .addCase(postBank.fulfilled, (state, action) => {
                state.name = action.payload.name;
                state.stk = action.payload.stk;
                state.nameUser = action.payload.nameUser;
                state.isEdit = false;
            })
    }
});

export const getBank = createAsyncThunk("bank/getBank", async (token) => {
    const response = await axiosGetBank(token.token);
    return response.data;
});

export const postBank = createAsyncThunk("bank/postBank", async (data) => {
    const response = await axiosPostBank(data.token, data.bank);
    return response.data;
});
