import {useLocation} from "react-router-dom";
import classNames from "classnames/bind";
import styles from './Sidebar.module.scss';
import {useSelector} from "react-redux";
import {navCollapsedSelector} from "../../../redux/selectors";
import config from "../../../config";
import SidebarItem from "./SidebarItem";

const cx = classNames.bind(styles);

function Sidebar() {
    const active = useSelector(navCollapsedSelector);

    const location = useLocation().pathname;

    const listNav = [
        {
            name: "Trang chủ",
            link: config.routes.home,
            i: "bi bi-grid",
        },
        {
            name: "Xu TDS",
            link: "#",
            i: "bi bi-menu-button-wide",
            // data_bs_target: "#components-nav",
            // data_bs_toggle: "collapse",
            children: [
                {
                    name: "Xu cần bán",
                    link: config.routes.coinstds,
                    i: "bi bi-circle",
                },
                {
                    name: "Hoá đơn",
                    link: config.routes.odertds,
                    i: "bi bi-circle",
                }
            ]
        },
    ];

    return (
        <aside id="sidebar" className={cx({active}, 'sidebar')}>
            <ul className="sidebar-nav" id="sidebar-nav">
                {listNav.map((item, index) => (
                    <li className="nav-item" key={index}>
                        {item.children ? (
                            <div>
                                <SidebarItem className={"nav-link collapsed"}>{item}</SidebarItem>
                                <ul
                                    // id={item.data_bs_target}
                                    className="nav-content"
                                    data-bs-parent="#sidebar-nav"
                                >
                                    {item.children.map((childItem, childIndex) => (
                                        <li key={childIndex}>
                                            <SidebarItem
                                                className={
                                                    location === childItem.link ? "active" : ""
                                                }
                                            >
                                                {childItem}
                                            </SidebarItem>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <SidebarItem
                                className={
                                    location === item.link ? "nav-link" : "nav-link collapsed"
                                }
                            >
                                {item}
                            </SidebarItem>
                        )}
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default Sidebar;