import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {axiosGetOderTds, axiosPostCoinAll, axiosPostCoinEven} from "../../api/coinTdsApi";

export const coinsTdsSlice = createSlice({
    name: 'coinsTds',
    initialState: {
        isSendCoins: false,
        listTds: [],
        odersTds: [],
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(sendCoinAll.fulfilled, (state, action) => {
                state.isSendCoins = false;
                state.listTds = action.payload;
            })
            .addCase(sendCoinAll.pending, (state) => {
                state.isSendCoins = true;
            })
            .addCase(sendCoinEven.fulfilled, (state, action) => {
                state.isSendCoins = false;
                state.listTds = action.payload;
            })
            .addCase(sendCoinEven.pending, (state) => {
                state.isSendCoins = true;
            })
            .addCase(getOderTds.fulfilled, (state, action) => {
                state.odersTds = action.payload.reverse();
            })
    }
});

export const sendCoinAll = createAsyncThunk("coinsTds/sendCoinAll", async (data) => {
    const response = await axiosPostCoinAll(data.token, data.coinsTds);
    return response.data;
});

export const sendCoinEven = createAsyncThunk("coinsTds/sendCoinEven", async (data) => {
    const response = await axiosPostCoinEven(data.token, data.coinsTds);
    return response.data;
});

export const getOderTds = createAsyncThunk("coinsTds/getOderTds", async (data) => {
    const response = await axiosGetOderTds(data.token);
    return response.data;
});