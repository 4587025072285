import {Link} from "react-router-dom";
import routesConfig from "../../../config/routes";
import Image from "../../../components/Image";
import NavProfile from "./NavProfile";
import NavButton from "./NavButton";
import {useDispatch, useSelector} from "react-redux";
import navSlice from "../../../features/cssNavCollapsed/navSlice";
import {isLoggedInSelector} from "../../../redux/selectors";

function Header() {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(isLoggedInSelector);
    const handleToggleNav = () => {
        dispatch(navSlice.actions.toggleNav());
    }

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                    <Link to={routesConfig.home} className="logo d-flex align-items-center">
                        <Image src={"assets/img/logo.png"} alt={'Nguyen Van A'}/>
                        <span className="d-none d-lg-block">Mmo5s</span>
                    </Link>
                <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleNav}></i>
            </div> {/* <!-- End Logo --> */}

            {isLoggedIn ? <NavProfile></NavProfile> : <NavButton />}

        </header>
    )
}

export default Header;