import {configureStore} from "@reduxjs/toolkit";
import cssNavCollapsed from "../features/cssNavCollapsed/navSlice";
import {authSlice} from "../features/auth/authSlice";
import {bankSlice} from "../features/bank/bankSlice";
import {settingPublicSlice} from "../features/setting/settingPublicSlice";
import {coinsTdsSlice} from "../features/coinsTds/coinsSlice";

const store = configureStore({
    reducer: {
        navSlice: cssNavCollapsed.reducer,
        authSlice: authSlice.reducer,
        bankSlice: bankSlice.reducer,
        settingPublicSlice: settingPublicSlice.reducer,
        coinsTdsSlice: coinsTdsSlice.reducer,
    },
});

export default store;