import axios from "axios";


const SETTING_API = "https://api.mmo5s.com/api/setting";

export const axiosGetPublicSetting = async (token) => {
    let result = null;
    try {
        result = await axios.get(`${SETTING_API}`, {
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};
