import {Link, useNavigate} from "react-router-dom";
import config from "../../config";
import './index.css';
import Image from "../../components/Image";
import {LockOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {authSlice, registerUser} from "../../features/auth/authSlice";
import {authSliceSlector} from "../../redux/selectors";
import {useEffect} from "react";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submit = (values: any) => {
        dispatch(registerUser({
            email: values.email,
            name: values.username,
            password: values.password,
        }))
    };

    const authSelector = useSelector(authSliceSlector)

    useEffect(() => {

        if (authSelector.isLoggedIn) {
            navigate(config.routes.home);
        }
    }, [authSelector.isLoggedIn, navigate]);

    useEffect(() => {

        if (authSelector.isRegisterIn) {
            navigate(config.routes.home);
            dispatch(authSlice.actions.toggleIsRegisterIn());
        }
    }, [authSelector.toggleIsRegisterIn, authSelector.isRegisterIn, dispatch, navigate]);

    return (<main>
            <div className="container">

                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <Link to={config.routes.home} className="logo d-flex align-items-center w-auto">
                                        <Image src="../assets/img/logo.png" alt="" />
                                        <span className="d-none d-lg-block">Mmo5s</span>
                                    </Link>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Tạo tài khoản</h5>
                                            <p className="text-center small">Nhập thông tin cá nhân của bạn để tạo tài khoản</p>
                                        </div>

                                        <Form
                                            name="normal_login"
                                            className="row g-3 needs-validation"
                                            initialValues={{ remember: true }}
                                            onFinish={submit}
                                        >
                                            <Form.Item
                                                name="email"
                                                rules={[{ required: true, message: 'Vui lòng nhập Email của bạn!' }]}
                                            >
                                                <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                                            </Form.Item>
                                            <Form.Item
                                                name="username"
                                                rules={[{ required: true, message: 'Vui lòng nhập tên người dùng của bạn!' }]}
                                            >
                                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                            </Form.Item>
                                            <Form.Item
                                                name="password"
                                                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu của bạn!' }]}
                                            >
                                                <Input
                                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                                    type="password"
                                                    placeholder="Password"
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                                    <Checkbox>Tôi đồng ý và chấp nhận các điều khoản và điều kiện</Checkbox>
                                                </Form.Item>
                                            </Form.Item>
                                            <Form.Item
                                                validateStatus={authSelector.isRegisterError ? 'error' : ''}
                                                help={authSelector.isRegisterError && 'Invalid username or password'}
                                            >
                                                <Button type="primary" htmlType="submit" className="login-form-button">Đăng ký</Button>
                                                Or <Link to={config.routes.login}>Đăng nhập!</Link>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
}

export default Login;