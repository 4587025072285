import {forwardRef, useState} from 'react'

const Image = forwardRef(({src, alt, className,  ...props}, ref) => {
    const [fallback, setFallBack] = useState('');

    const handleError = () => {
        setFallBack('assets/img/no-image.png')
    }

    return (
        <img ref={ref} src={fallback || src} alt={alt} {...props}
             onError={handleError} className={className}/>
    );
});

export default Image;