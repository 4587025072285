import axios from "axios";


const BANK_API = "https://api.mmo5s.com/api/bank/";

export const axiosGetBank = async (token) => {
    let result = null;
    try {
        result = await axios.get(`${BANK_API}user`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};

export const axiosPostBank = async (token, bank) => {
    let result = null;
    try {
        result = await axios.post(`${BANK_API}`,bank,{
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};