import Home from "../pages/Home";
import Coins from "../pages/Cointds/coins";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Odertds from "../pages/Cointds/odertds";

import config from '../config'

const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.coinstds, component: Coins },
    { path: config.routes.login, component: Login, layout: null},
    { path: config.routes.register, component: Register, layout: null},
    { path: config.routes.odertds, component: Odertds },
]

const privateRoutes = [

]

export { publicRoutes, privateRoutes }