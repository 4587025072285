import axios from "axios";


const AUTH_API = "https://api.mmo5s.com/api/auth/";

export const getUserAccount = async (token) => {
    let result = null;
    try {
        result = await axios.get(`${AUTH_API}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};

export const axiosLoginUser = async (user) => {
    let result = null;
    try {
        result = await axios.post(`${AUTH_API}login`,user, {
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};

export const axiosRegisterUser = async (user) => {
    let result = null;
    try {
        result = await axios.post(`${AUTH_API}register`,user, {
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};