import {Link} from "react-router-dom";
import config from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {
    bankSelector, coinsTdsSelector,
    isLoggedInSelector,
    settingPublicSelector,
    tokenSelector
} from "../../redux/selectors";
import {useEffect, useState} from "react";
import {bankSlice, getBank, postBank} from "../../features/bank/bankSlice";
import Modal from "../../components/Modal";
import {sendCoinAll, sendCoinEven} from "../../features/coinsTds/coinsSlice";
import {Radio} from "antd";

function Coins() {
    const dispatch = useDispatch();
    const [bank, setBank] = useState({
        name: '',
        stk: '',
        nameUser: '',
    });
    const [sumCoin, setSumCoin] = useState('');
    const [sumMoney, setSumMoney] = useState('');

    const [accTds, setAccTds] = useState("");
    const [typeSend, setTypeSend] = useState("All");

    const [showModal, setShowModal] = useState(false);

    const isLoggedIn = useSelector(isLoggedInSelector);
    const token = useSelector(tokenSelector);
    const bankSe = useSelector(bankSelector);
    const settingPublic = useSelector(settingPublicSelector);
    const coinsTds = useSelector(coinsTdsSelector);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getBank({token: token}))
        } else {
            dispatch(bankSlice.actions.clearBank())
        }
        setBank({
            name: bankSe.name,
            stk: bankSe.stk,
            nameUser: bankSe.nameUser,
        })
    }, [bankSe.name, bankSe.stk, bankSe.nameUser,dispatch, isLoggedIn, token]);

    useEffect(() => {
        const values = coinsTds.listTds.map(json => {
            console.log(json);
            return json.user === "ALLSUMCOINSEND"
                ? (setSumCoin(json.xuSend) , setSumMoney(json.password))
                : `${json.user}|${json.password}|${json.xu}|${json.message}|${json.xuSend}`;
        }).join('\n');

        setAccTds(values)
    }, [coinsTds.listTds]);

    const handleEdit = () => {
        dispatch(bankSlice.actions.toggleEdit());
    }

    const handleSave = () => {
        dispatch(postBank({
            token: token,
            bank: {
                name: bank.name,
                stk: bank.stk,
                nameUser: bank.nameUser,
                status: "main"
            }
        }));
    }

    const handleChangeBank = event =>
        setBank({...bank, [event.target.name]: event.target.value});

    const handleChangeAccTds = event =>
        setAccTds(event.target.value);

    const handleChangeTypeSend = (e: RadioChangeEvent) => {
        setTypeSend(e.target.value);
    };

    const handleSendCoinTds = () => {
        let lines = accTds.split('\n');
        let jsonList = lines.map(line => {
            if (line.trim() === '' || line.trim() === 'a|' || line.trim() === '|a') {
                return null;
            }
            let [user, password] = line.split('|');
            return {user, password};
        }).filter(item => item !== null);
        if (typeSend === "All"){
            dispatch(sendCoinAll({token: token, coinsTds: jsonList}))
        }else {
            dispatch(sendCoinEven({token: token, coinsTds: jsonList}))
        }
    }

    function isBankInfoComplete(bank) {
        return bank.name !== '' && bank.stk !== '' && bank.nameUser !== '';
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    return (
        <main id="main" className="main">

            <div className="pagetitle">
                <h1>Xu cần bán</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={config.routes.home}>Home</Link>
                        </li>
                        <li className="breadcrumb-item">Xu TDS</li>
                        <li className="breadcrumb-item active">Xu cần bán</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">

                    <div className="col-lg-6 mt-3">

                        <div className="card">
                            <div className="card-header">
                                <h5><i className="ri-file-add-fill"></i> CHUYỂN XU</h5>
                            </div>
                            <div className="card-body">

                                <form className="row g-3 mt-1">

                                    <div className="col-12" style={{color: '#008000'}}>
                                        <h5>Định dạng vào name|pass, định dạng ra name|pass|xu|trạng thái. Xu sẽ tự làm tròn xuống dưới ví dụ như
                                            9m9 thì làm tròn còn 9m nên mọi người tự canh xu nhá. 10h tối hằng ngày sẽ chốt
                                            sổ xu, sau đó sẽ chuyển tiền.</h5>
                                        <span>Tổng xu: {sumCoin}</span><br/>
                                        <span>Tổng tiền: {sumMoney}</span><br/>
                                        <span>Rate thu xu: {settingPublic.rateCoinTds}</span>
                                        <div>
                                            Kiểu chuyển: &nbsp;
                                            <Radio.Group onChange={handleChangeTypeSend} value={typeSend}>
                                                <Radio value={"All"}>Chuyển hết</Radio>
                                                <Radio value={"Even"}>Chuyển chẵn</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label">List</label>
                                        <textarea className="form-control" rows="20" value={accTds} onChange={handleChangeAccTds}></textarea>
                                    </div>

                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary rounded-pill me-2" data-bs-toggle="modal" data-bs-target="#deterministically">Bán xu
                                        </button>
                                        <button type="reset" className="btn btn-secondary rounded-pill">Reset</button>
                                        <div className="modal fade" id="deterministically" tabIndex="-1">
                                            <div className="modal-dialog modal-dialog-centered">
                                                { !coinsTds.isSendCoins ? (
                                                    isBankInfoComplete(bank) ? (
                                                        <Modal body={"Xác nhận chuyển xu"}
                                                               footer={<>
                                                                   <button type="button" className="btn btn-danger rounded-pill"
                                                                           data-bs-dismiss="modal">No
                                                                   </button>
                                                                   <button type="button" className="btn btn-primary rounded-pill" onClick={handleSendCoinTds}>Yes
                                                                   </button>
                                                               </>}
                                                        />
                                                    ) : (
                                                        <Modal body={"Chưa điền đầy đủ thông tin ngân hàng, bấm vào edit để điền thông tin ngân hàng, sau đó bấm save và refresh lại trang."}
                                                               footer={<>
                                                                   <button type="button" className="btn btn-danger rounded-pill"
                                                                           data-bs-dismiss="modal">Đóng
                                                                   </button>
                                                               </>}
                                                        />
                                                    )
                                                ) : (
                                                    <Modal body={<>
                                                        <div className="spinner-border text-primary" style={{ width: '50px', height: '50px' }} role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                        <div>Đang chuyển xu vui lòng không tắt trình duyệt</div>
                                                    </>}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 mt-3">

                        <div className="card">
                            <div className="card-header">
                                <h5><i className="ri-file-add-fill"></i> Tài khoản ngân hàng</h5>
                            </div>
                            <div className="card-body">
                                <div className="col-12 mt-3" style={{color: '#008000'}}>
                                    <h5>Nếu không điền tài khoản ngân hàng mất tiền ráng chịu hehe. Momo vẫn được.</h5>
                                </div>


                                <form className="row g-3 mt-1">
                                    <div className="col-12">
                                        <label htmlFor="inputBankName" className="form-label">Tên ngân hàng</label>
                                        <input type="text" className="form-control" name={"name"}
                                               onChange={handleChangeBank} disabled={!bankSe.isEdit} value={bank.name}/>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="inputSTK" className="form-label">STK</label>
                                        <input type="text" className="form-control" name={"stk"}
                                               onChange={handleChangeBank} disabled={!bankSe.isEdit} value={bank.stk}/>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="inputNameUserBank" className="form-label">Tên người nhận</label>
                                        <input type="text" className="form-control" name={"nameUser"}
                                               onChange={handleChangeBank} disabled={!bankSe.isEdit}
                                               value={bank.nameUser}/>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary me-2"
                                                onClick={handleSave}>Save
                                        </button>
                                        <button type="reset" className="btn btn-secondary" onClick={handleEdit}>Edit
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </main>
    );
}

export default Coins;