import Header from "../components/Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import {useSelector} from "react-redux";
import {navCollapsedSelector} from "../../redux/selectors";
import classNames from "classnames/bind";
import styles from "./DefaultLayout.module.scss";

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    const active = useSelector(navCollapsedSelector);

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="mainbody" className={cx({active})}>{children}</div>
        </div>
    )
}

export default DefaultLayout;