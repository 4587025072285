import {Link} from "react-router-dom";
import config from "../../config";
import Tabletds from "./table/tabletds";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {tokenSelector} from "../../redux/selectors";
import {getOderTds} from "../../features/coinsTds/coinsSlice";


function Odertds() {
    const dispatch = useDispatch();

    const token = useSelector(tokenSelector);

    useEffect(() => {
        if (token) {
            dispatch(getOderTds({token: token}))
        }
    }, [dispatch, token]);

    return (
        <main id="main" className="main">

            <div className="pagetitle">
                <h1>Hoá đơn</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={config.routes.home}>Home</Link>
                        </li>
                        <li className="breadcrumb-item">Xu TDS</li>
                        <li className="breadcrumb-item active">Hoá đơn</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-12">

                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Products</h5>

                                <Tabletds></Tabletds>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </main>
    );
}

export default Odertds;