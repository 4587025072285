import {Link} from "react-router-dom";

function SidebarItem({ children, className }) {
    return (
        <Link className={className} data-bs-target={children.data_bs_target}
              data-bs-toggle={children.data_bs_toggle} to={children.link}>
            <i className={children.i}></i>
            <span>{children.name}</span>
        </Link>
    );
}

export default SidebarItem;