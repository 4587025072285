import {Table} from "antd";
import Column from "antd/es/table/Column";
import {useSelector} from "react-redux";
import {coinsTdsSelector} from "../../../redux/selectors";
import {useEffect, useState} from "react";


function Tabletds() {
    const data = useSelector(coinsTdsSelector);

    const [dataOderTds, setDataOderTds] = useState([]);

    useEffect(() => {
        setDataOderTds(data.odersTds);
    },[data.odersTds]);

    return (
        <Table dataSource={dataOderTds} rowKey="id">
            <Column title="Tên" dataIndex="name" key="name" />
            <Column title="Tổng xu" dataIndex="sumCoin" key="sumCoin" />
            <Column title="Tổng tiền" dataIndex="sumMoney" key="sumMoney" />
            <Column title="Ngày" dataIndex="date" key="date" />
        </Table>
    );
}

export default Tabletds;