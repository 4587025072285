import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: 'cssNavCollapsed',
    initialState: {
        active: false,
    },
    reducers: {
        toggleNav: (state, action) => {
            state.active = !state.active;
        }
    }
})