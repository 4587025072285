import axios from "axios";


const COINSTDS_API = "https://api.mmo5s.com/api/tds/";
const ODERTDS_API = "https://api.mmo5s.com/api/odertds/";

export const axiosPostCoinAll = async (token, coinsTds) => {
    let result = null;
    try {
        result = await axios.post(`${COINSTDS_API}all`,coinsTds,{
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};

export const axiosPostCoinEven = async (token, coinsTds) => {
    let result = null;
    try {
        result = await axios.post(`${COINSTDS_API}even`,coinsTds,{
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};

export const axiosGetOderTds = async (token) => {
    let result = null;
    try {
        result = await axios.get(`${ODERTDS_API}all`,{
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });
    } catch (e) {
        console.log("Find books API error: " + e);
    }
    return result;
};