import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {axiosGetPublicSetting} from "../../api/settingApi";

export const settingPublicSlice = createSlice({
    name: 'settingPublicSlice',
    initialState: {
        rateCoinTds: "",
        isCollectionCoinsTds: true,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(getSettingPublic.fulfilled, (state, action) => {
                state.rateCoinTds = action.payload.rateCoinTds;
                state.isCollectionCoinsTds = action.payload.collectionCoinsTds;
            })
    }
});

export const getSettingPublic = createAsyncThunk("settingPublicSlice/getSetting", async () => {
    const response = await axiosGetPublicSetting();
    return response.data;
});
