import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React, {Fragment, useEffect} from "react";
import {publicRoutes} from "./routing";
import DefaultLayout from "./layout";
import jwtDecode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "./features/auth/authSlice";
import {isLoggedInSelector} from "./redux/selectors";
import {getSettingPublic} from "./features/setting/settingPublicSlice";

function App() {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(isLoggedInSelector);

    useEffect(() => {
        const tokenChecked = localStorage.getItem('mmo5sjwt');
        if (tokenChecked) {
            const decodedToken = jwtDecode(tokenChecked);
            const currentDate = new Date();

            if (decodedToken.exp * 1000 > currentDate.getTime()) {
                dispatch(getUser({token : tokenChecked}))
            }
        }
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        dispatch(getSettingPublic());
    })

    return (
        <Router>
            <Routes>
                {publicRoutes.map((route, index) => {
                    let Layout = DefaultLayout;
                    if (route.layout){
                        Layout = route.layout
                    }else if (route.layout === null) {
                        Layout = Fragment
                    }
                    const Page = route.component
                    return <Route key={index} path={route.path} element={
                        <Layout>
                            <Page />
                        </Layout>} />
                })}
            </Routes>
        </Router>);
}

export default App;
