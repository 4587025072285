import {Link} from "react-router-dom";
import config from "../../../../config";

function NavButton() {
    return (
        <nav className="header-nav ms-auto" id="navLogAndReg">

            <ul className="d-flex align-items-center">
                <Link className="btn btn-primary w-100 me-2" to={config.routes.register}>
                    <span>Register</span>
                </Link>

                <Link className="btn btn-primary w-100 me-4" to={config.routes.login}>
                    <span>Login</span>
                </Link>
            </ul>

        </nav>
    );
}

export default NavButton;