
function Modal({body, footer}) {
    return (
        <div className="modal-content">
            {body && (
                <div className="modal-body"
                     style={{ color: 'black', fontWeight: 'bold', fontSize: '20px' }}
                     id="modal-body">
                    {body}
                </div>
            )}
            {footer && (
                <div className="modal-footer" id="modal-footer">
                    {footer}
                </div>
            )}
        </div>
    );
}

export default Modal;