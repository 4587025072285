import {Link} from "react-router-dom";
import Image from "../../../../components/Image";
import {useDispatch} from "react-redux";
import {authSlice} from "../../../../features/auth/authSlice";
function NavProfile() {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(authSlice.actions.logout());
    }

    return (
        <nav className="header-nav ms-auto" id="navPro5">
            <ul className="d-flex align-items-center">

                <li className="nav-item dropdown pe-3">

                    <Link className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown" to={"#"}>
                        <Image src={"assets/img/profile-img.jpg"} alt={"Profile"} className={"rounded-circle"} />
                        <span className="d-none d-md-block dropdown-toggle ps-2" id="namepop"></span>
                    </Link> {/* <!-- End Profile Image Icon --> */}

                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li className="dropdown-header">
                            <h6>Member</h6>
                        </li>
                        <li>
                            <hr className="dropdown-divider"/>
                        </li>
                        <li>
                            <Link className="dropdown-item d-flex align-items-center" to={"#"}>
                                <i className="bi bi-person"></i>
                                <span>My Profile</span>
                            </Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        {/*<li>*/}
                        {/*    <a className="dropdown-item d-flex align-items-center"*/}
                        {/*       href="./admin/dashboard.html">*/}
                        {/*        <i className="bi bi-gear"></i>*/}
                        {/*        <span>Admin Settings</span>*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <Link className="dropdown-item d-flex align-items-center" to={"#"}>
                                <i className="bi bi-gear"></i>
                                <span>Account Settings</span>
                            </Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <Link className="dropdown-item d-flex align-items-center" onClick={handleLogout} to={"#"}>
                                <i className="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </Link>
                        </li>

                    </ul>

                </li>

            </ul>
        </nav>
    )
}

export default NavProfile;